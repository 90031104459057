/** @jsx jsx */
import { jsx } from "theme-ui"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Page = () => (
  <Layout title="Newsletter" newsletter={true}>
    <SEO title="Newsletter" />
  </Layout>
)

export default Page
